<template>
  <div class="my-notifications">
    <div class="container-xl">
      <div class="card">
        <div class="card-header">
          <div class="card-title">Настройки уведомлений</div>
        </div>
        <div class="card-body">
          <data-loading :loading="dataLoading"></data-loading>
          <form v-if="!dataLoading" @submit.prevent.stop="save">
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input v-model="notificationSettings.mailSettings.systemNotifications"
                       type="checkbox"
                       class="custom-control-input"
                       id="mail-system-notifications">
                <label class="custom-control-label" for="mail-system-notifications">
                  Системные уведомления (пополнение счета, вывод средств, инвестиция в проект и т.д.)
                </label>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input v-model="notificationSettings.mailSettings.newsletters"
                       type="checkbox"
                       class="custom-control-input"
                       id="mail-newsletters">
                <label class="custom-control-label" for="mail-newsletters">
                  Новостные рассылки
                </label>
              </div>
            </div>
            <div class="form-group mt-4">
              <button type="submit" class="btn btn-lg btn-success">
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <processing :show="processing"></processing>
  </div>
</template>

<script>
import { DataLoading, Processing } from "@/components/common";
import { createNotificationSettingsManager } from "@/utils/di";

export default {
  name: "Notifications",
  components: {
    DataLoading,
    Processing
  },
  async mounted() {
    this.dataLoading = true;
    const manager = await createNotificationSettingsManager();
    Object.assign(this.notificationSettings, await manager.getNotificationSettings());
    this.dataLoading = false;
  },
  data() {
    return {
      notificationSettings: {
        mailSettings: {}
      },
      dataLoading: false,
      processing: false
    };
  },
  methods: {
    async save() {
      this.processing = true;
      try {
        const manager = await createNotificationSettingsManager();
        await manager.putNotificationSettings(this.notificationSettings);
      } finally {
        this.processing = false;
      }
    }
  }
}
</script>
